<section class="mt-0">
  <!-- Modal tratamiento de datos -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="privacyPolicyModalLabel">Política de Tratamiento de Datos</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>En Positiva Compañía de Seguros, nos comprometemos a proteger la privacidad de nuestros usuarios. La
            información personal recolectada es utilizada exclusivamente para [fines específicos como, por ejemplo,
            mejorar la experiencia del usuario, procesar solicitudes, etc.]. Implementamos medidas de seguridad para
            garantizar la protección de tus datos y evitar accesos no autorizados. No compartimos tus datos personales
            con terceros sin tu consentimiento, salvo en los casos exigidos por la ley. Para conocer más detalles sobre
            cómo manejamos tu información, por favor visita nuestro enlace.

            Para conocer más detalles sobre cómo manejamos tu información, por favor visita nuestro enlace <br> <a
              href="https://www.positiva.gov.co/en/web/guest/tratamiento-de-datos-personales" target="_blank">Políticas
              de Tratamiento de Datos.</a></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No Acepto</button>
          <button type="button" class="btn btn-primary" (click)="acceptPolicy()" data-bs-dismiss="modal">Acepto</button>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="paso>=2">
    <div class="text-center mt-5 mb-5">
      <img src="assets/img/logo-positiva-1.png" width="440px">
    </div>

    <div class="text-center mb-5" *ngIf="paso !=9">
      <button [disabled]="paso==1" class="btn btn-sm btn-outline-secondary" (click)="backPaso()"><i
          class="fa fa-chevron-left"></i></button>
      <button class="btn btn-sm btn-outline-secondary" type="button" (click)="homePaso();paso=1"><i
          class="fa fa-home"></i></button>
    </div>

  </div>


  <!-- PASO 1 INICIO -->
  <div *ngIf="paso==1" id="paso1" class="row">
    <video src="assets/into.mp4" autoplay loop>
      <p>Su navegador no soporta vídeos HTML5.</p>
    </video>

    <div class="col-12 mt-3">
      <ngx-typed-js [strings]="['Bienvenido a nuestro cotizador de seguros exequiales']" [shuffle]="false"
        [fadeOut]="true" [showCursor]="false" (completed)="doSmth(1)">
        <h1 class="text-center"> <span class="typing"></span></h1>
      </ngx-typed-js>

    </div>

    <div class="col-12 text-center mt-2 mb-2" *ngIf="accion==1">
      <button type="button" (click)="paso=2" class="btn btn-lg btn-warning">Siguiente <i
          class="fa fa-chevron-right"></i></button>
    </div>



    <!-- <button type="button" (click)="realizarLlamadaSoap('1075221976')" class="btn btn-lg btn-primary">Realizar Consulta</button> -->

  </div>


  <!-- PASO 2  NOMBRE-->
  <div *ngIf="paso==2" id="paso2" class="row">
    <div class="col-12">
      <!-- <ngx-typed-js [strings]="['Puede indicarnos cual es su nombre']" [showCursor]="false" (completed)="doSmth(2)">
        <h2 class="text-center"> <span class="typing"></span></h2>
      </ngx-typed-js> -->
      <ngx-typed-js [strings]="['Puede indicarnos cual es su número de indentificación']" [showCursor]="false"
        (completed)="doSmth(2)">
        <h2 class="text-center"> <span class="typing"></span></h2>
      </ngx-typed-js>
    </div>
    <!-- <div class="col-12 text-center mt-3" *ngIf="accion ==2">
      <div class="input-group input-group-lg m-auto" style="width: 450px;">
        <input type="text" [(ngModel)]="nombre" class="form-control" placeholder="Nombre completo">
        <button *ngIf="nombre" type="button" (click)="paso=3"
          class="btn btn-lg btn-warning">Siguiente <i class="fa fa-chevron-right"></i></button>
      </div>
    </div> -->
    <div class="col-12 text-center mt-3" *ngIf="accion ==2">
      <div class="input-group input-group-lg m-auto" style="width: 450px;">
        <input type="number" [(ngModel)]="documento" class="form-control" placeholder="Número documento">
        <button *ngIf="documento || !loaded" type="button" (click)="setDocumento()"
          class="btn btn-lg btn-warning">Siguiente <i class="fa fa-chevron-right"></i></button>
        <button *ngIf="loaded" type="button" class="btn btn-lg btn-warning">
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </div>
      <div class="row mt-4" *ngIf="!listaRestrictivaTomador">
        <div class="col-12">
          <!-- {{loaded}} -->
          <!-- <div *ngIf="loaded" class="spinner-grow" role="status">
              <span class="visually-hidden">Loading...</span>
            </div> -->
          <h4 *ngIf="nombre">Su nombre es?</h4>
          <h3>{{nombre}}</h3>
        </div>
        <div class="col-12" *ngIf="nombre">
          <div class="btn-group" role="group" aria-label="Basic outlined example">
            <button type="button" class="btn btn-outline-primary" (click)="paso=3">SI</button>
            <button type="button" class="btn btn-outline-primary" (click)="nombre=''">NO</button>
          </div>
        </div>
        <div class="col-12">
          <h3>{{msg}}</h3>
        </div>
      </div>
      <div class="row mt-4" *ngIf="listaRestrictivaTomador">
        <h4 class="text-center">Pedimos disculpas no tenemos productos disponibles para ti.</h4>
      </div>
    </div>
  </div>

  <!-- PASO 3  NACIMIENTO-->
  <div *ngIf="paso==3" id="paso3" class="row">

    <div class="col-12">
      <ngx-typed-js [strings]="['cual es su fecha de nacimiento']" [showCursor]="false" (completed)="doSmth(3)">
        <h2 class="text-center"><span style="color:#ff7800">{{nombre | titlecase}} </span> <span class="typing"></span>
        </h2>
      </ngx-typed-js>
    </div>
    <div class="col-12 text-center mt-3" *ngIf="accion ==3">
      <div class="input-group input-group-lg m-auto" style="width: 300px;">
        <input type="date" [(ngModel)]="fecha_nacimiento" class="form-control" placeholder="Fecha nacimiento"
          (change)="calcularEdad()">
        <button *ngIf="fecha_nacimiento" type="button" (click)="paso=4" class="btn btn-lg btn-warning"
          [disabled]="edad >='71' || edad <='17'">Siguiente <i class="fa fa-chevron-right"></i></button>
      </div>
      <h1 class="mt-4" *ngIf="edad >=1">{{edad}} Años</h1>
      <small *ngIf="edad >= '71' || edad <= '17'" style="margin-top: 2%;">Pedimos disculpas pero No cumples los
        requisitos de edad para nuestros productos exquiales</small>
    </div>
  </div>

  <!-- PASO 4 UBICACION -->
  <div *ngIf="paso==4" id="paso4" class="row">
    <div class="col-12">
      <ngx-typed-js [strings]="['¿En qué ciudad o Municipio se encuentra ubicado?']" [showCursor]="false"
        (completed)="doSmth(4)">
        <h2 class="text-center"> <span class="typing"></span></h2>
      </ngx-typed-js>
    </div>
    <div class="col-12 text-center mt-3" *ngIf="accion ==4">
      <div class="input-group input-group-lg m-auto" style="width: 300px;">
        <input type="text" [(ngModel)]="ciudad" class="form-control" placeholder="ciudad">
        <button *ngIf="ciudad" type="button" (click)="paso=5" class="btn btn-lg btn-warning">Siguiente <i
            class="fa fa-chevron-right"></i></button>
      </div>
    </div>
  </div>


  <!-- PASO 5  ESTADO CIVIL-->
  <div *ngIf="paso==5" id="paso5" class="row">
    <div class="col-12">
      <ngx-typed-js [strings]="['¿Cuál es su estado civil? ']" [showCursor]="false" (completed)="doSmth(5)">
        <h2 class="text-center"> <span class="typing"></span></h2>
      </ngx-typed-js>
    </div>
    <div class="col-12 text-center mt-3" *ngIf="accion ==5">
      <div class="m-auto" style="width: 400px;">
        <div class="row">
          <div class="col-12 mb-2"><button type="button" class="btn btn-block btn-lg btn-secondary"
              (click)="estado_civil = 'soltero'; paso =6">Soltero</button></div>
          <div class="col-12"><button type="button" class="btn btn-block btn-lg btn-secondary"
              (click)="estado_civil = 'casado'; paso =6">Casado (union libre)</button></div>
          <div class="col-12 mt-4">
            <!-- <button *ngIf="ciudad" type="button" (click)="paso=6" class="btn btn-lg btn-warning">Siguiente</button> -->
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- PASO 6  PRODUCTOS-->
  <div class="row m-5" *ngIf="paso ==6">

    <ngx-typed-js [strings]="['estas son las personas que tú puedes incluir en el plane exequial.']"
      [showCursor]="false" (completed)="doSmth(6)">
      <h2 class="text-center"><span style="color:#ff7800">{{nombre | titlecase}} </span> <span class="typing"></span>
      </h2>
    </ngx-typed-js>


    <div class="col-md-6 fadeIn mt-5 m-auto" *ngFor="let item of productos" id="producto{{item.id}}"
      style="display: none;">
      <div class="card">
        <div class="card-body" style="min-height: 150px;">
          <h3 class="text-center">{{item.nombre | titlecase}}</h3>
          <p class="mt-3" [innerHTML]="item.detalle"></p>
          <div class="text-center mt-3">
            <button class="btn btn-lg btn-warning" type="button" (click)="selectProducto(item)">Seleccionar</button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- PASO 7  DETALLES PRODUCTO-->
  <div *ngIf="paso ==7" class="mb-5">
    <!-- {{producto | json}} -->
    <div class="row m-5">
      <div class="row" id="planes">
        <div class="col-12 text-center mt-5 mb-5">
          <h2>{{producto.nombre | titlecase}} Costo Anual</h2>
          <small>Dependiendo del plan de beneficios que tú desees, puedes elegir una de las siguientes
            alternativas</small>
        </div>
        <div class="col-3 text-center">
          <div class="card">
            <div class="card-body">
              <div>
                <h4 style="font-size: 0.8em;">Plan Total</h4>
              </div>
              <h3 style="font-size: 0.9em;">${{producto.plan_total | decimal}}</h3>
              <button class="btn btn-lg btn-warning" type="button"
                (click)="selectPlan(producto, producto.plan_total,producto.id,'Plan Total',producto.persona_adicional,4)">Elegir</button>
            </div>
          </div>
        </div>

        <div class="col-3 text-center">
          <div class="card">
            <div class="card-body">
              <div>
                <h4 style="font-size: 0.8em;">Plan Mas</h4>
              </div>
              <h3 style="font-size: 0.9em;">${{producto.plan_mas | decimal}}</h3>
              <button class="btn btn-lg btn-warning" type="button"
                (click)="selectPlan(producto, producto.plan_mas,producto.id,'Plan Mas',producto.persona_adicional,3)">Elegir</button>
            </div>
          </div>
        </div>

        <div class="col-3 text-center">
          <div class="card">
            <div class="card-body">
              <div>
                <h4 style="font-size: 0.8em;">Plan Plus</h4>
              </div>
              <h3 style="font-size: 0.9em;">${{producto.plan_plus | decimal}}</h3>
              <button class="btn btn-lg btn-warning" type="button"
                (click)="selectPlan(producto, producto.plan_plus,producto.id,'Plan Plus',producto.persona_adicional,2)">Elegir</button>
            </div>
          </div>
        </div>

        <div class="col-3 text-center">
          <div class="card">
            <div class="card-body">
              <div>
                <h4 style="font-size: 0.8em;">Plan Esencial</h4>
              </div>
              <h3 style="font-size: 0.9em;">${{producto.plan_esencial | decimal}}</h3>
              <button class="btn btn-lg btn-warning" type="button"
                (click)="selectPlan(producto, producto.plan_esencial,producto.id,'Plan Esencial',producto.persona_adicional,1)">Elegir</button>
            </div>
          </div>
        </div>

      </div>

      <div>
        <img src="assets/bg-detalle-plan.jpg" class="img-fluid">
        <a style="color:#ff7800" class="ml-3"
          href="https://www.positiva.gov.co/documents/20123/2029346/Clausulado+Exequias.pdf" target="_blank"><u>Conoce
            mas información en nuestro clausulado.</u></a>
      </div>

      <div class="row" id="planes">
        <div class="col-12 text-center mt-5 mb-5">
          <h2>{{producto.nombre | titlecase}} Costo Anual</h2>
          <small>Dependiendo del plan de beneficios que tú desees, puedes elegir una de las siguientes
            alternativas</small>
        </div>
        <div class="col-md-3 col-xs-6 text-center">
          <div class="card">
            <div class="card-body">
              <div>
                <h4>Plan Total</h4>
              </div>
              <h3>${{producto.plan_total | decimal}}</h3>
              <button class="btn btn-lg btn-warning" type="button"
                (click)="selectPlan(producto, producto.plan_total,producto.id,'Plan Total',producto.persona_adicional,4)">Elegir</button>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-xs-6 text-center">
          <div class="card">
            <div class="card-body">
              <div>
                <h4>Plan Mas</h4>
              </div>
              <h3>${{producto.plan_mas | decimal}}</h3>
              <button class="btn btn-lg btn-warning" type="button"
                (click)="selectPlan(producto, producto.plan_mas,producto.id,'Plan Mas',producto.persona_adicional,3)">Elegir</button>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-xs-6 text-center">
          <div class="card">
            <div class="card-body">
              <div>
                <h4>Plan Plus</h4>
              </div>
              <h3>${{producto.plan_plus | decimal}}</h3>
              <button class="btn btn-lg btn-warning" type="button"
                (click)="selectPlan(producto, producto.plan_plus,producto.id,'Plan Plus',producto.persona_adicional,2)">Elegir</button>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-xs-6 text-center">
          <div class="card">
            <div class="card-body">
              <div>
                <h4>Plan Esencial</h4>
              </div>
              <h3>${{producto.plan_esencial | decimal}}</h3>
              <button class="btn btn-lg btn-warning" type="button"
                (click)="selectPlan(producto, producto.plan_esencial,producto.id,'Plan Esencial',producto.persona_adicional,1)">Elegir</button>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

  <!-- PASO 8 RESUMEN -->
  <div class="row m-4" *ngIf="paso ==8">


    <!-- Modal agregar beneficiarios principales -->
    <div class="modal fade" id="addBeneficiariosPrincipales" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-center">Agregar Beneficiarios principales</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 text-center">
                <h3>{{parentesco | titlecase}}</h3>
                <span *ngIf="parentesco =='hijos'">hasta los cuarenta (40) años más 364 dias</span>
              </div>

              <div *ngIf="parentesco !='padres'" class="col-12 text-center mt-3">
                <h6>El beneficiario es menor de edad?</h6>
                <div class="form-check form-check-inline">
                  <input [(ngModel)]="be_menor_edad" class="form-check-input" type="radio" (click)="clearFields()"
                    name="inlineRadioOptions" id="inlineRadio3" value="si">
                  <label class="form-check-label" for="inlineRadio3">SI</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [(ngModel)]="be_menor_edad" class="form-check-input" type="radio" (click)="clearFields()"
                    name="inlineRadioOptions" id="inlineRadio4" value="no">
                  <label class="form-check-label" for="inlineRadio4">NO</label>
                </div>
              </div>



              <div class="col-12 text-center mt-3" *ngIf="be_menor_edad == 'no' || be_menor_edad == 'si'">
                <div class="input-group input-group-lg m-auto" style="width: 300px;">
                  <input type="number" [(ngModel)]="be_documento" class="form-control" placeholder="Documento">
                  <button class="btn btn-outline-secondary" type="button"
                    *ngIf="be_menor_edad == 'no' && be_documento != documento"
                    (click)="setDocumentoBenficiario(be_documento)">
                    <i *ngIf="!loaded" class="fa fa-check"></i> <!-- Ícono de validación -->
                    <div *ngIf="loaded" class="spinner-grow" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                  <span class="mt-3" *ngIf="be_documento == documento" style="color: red;">No puedes asegurar al tomador
                    principal del servicio como beneficiario</span>
                </div>
              </div>

              <div class="col-12 text-center mt-3" *ngIf="be_menor_edad=='si'">
                <div class="input-group input-group-lg m-auto" style="width: 300px;">
                  <input type="text" [(ngModel)]="be_nombre" class="form-control" placeholder="Nombre">
                </div>
              </div>
              <div class="col-12 text-center mt-3" *ngIf="be_menor_edad=='no'">
                <h5>{{be_nombre}}</h5>
              </div>

              <div class="col-12 text-center mt-3" *ngIf="be_menor_edad=='si'">
                <div class="form-check form-check-inline">
                  <input [(ngModel)]="be_genero" class="form-check-input" type="radio" name="inlineRadioOptions"
                    id="inlineRadio1" value="masculino">
                  <label class="form-check-label" for="inlineRadio1">Masculino</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [(ngModel)]="be_genero" class="form-check-input" type="radio" name="inlineRadioOptions"
                    id="inlineRadio2" value="femenino">
                  <label class="form-check-label" for="inlineRadio2">Femenino</label>
                </div>
              </div>

              <div class="col-12 text-center mt-1" *ngIf="be_menor_edad=='no'">
                <h6>{{be_genero}}</h6>
              </div>


              <div class="col-12 text-center mt-3" *ngIf="be_menor_edad=='si'">
                <div class="input-group input-group-lg m-auto" style="width: 300px;">
                  <input type="date" [(ngModel)]="be_fecha_nacimiento" class="form-control"
                    placeholder="Fecha nacimiento" (change)="calcularEdadBeneficiarios()">
                </div>

                <h1 class="mt-4" *ngIf="be_edad >=1 && be_edad <=100">{{be_edad}} Años</h1>
                <span *ngIf="be_edad >=18" style="color: red;">Debe de ser menor de edad</span>

              </div>

              <div class="col-12 text-center mt-1" *ngIf="be_menor_edad=='no'">
                <h4 *ngIf="be_edad">{{be_edad}} Años</h4>
              </div>

              <div>{{msg}}</div>

              <div class="col-12 text-center">
                <h5 style="color: red !important;">{{msgBe}}</h5>
              </div>
              <div class="col-12 text-center mt-3">
                <div *ngIf="parentesco =='conyugue'">
                  <span *ngIf="be_edad <=71 && be_edad >=18">
                    <div><button [disabled]="!(be_nombre && be_genero && be_documento && be_fecha_nacimiento)"
                        class="btn btn-primary" (click)="agregarBeneficiarioPrincipal('hijo')">Agregar
                        beneficiario</button></div>
                  </span>
                  <span *ngIf="be_edad > 71"><b>hasta los cuarenta (70) años más 364 dias</b></span>
                </div>
                <div *ngIf="parentesco =='hijos'">
                  <span *ngIf="be_edad <=41 && be_edad >=0">
                    <span *ngIf="be_menor_edad =='si' && be_edad <=18">
                      <button [disabled]="!(be_nombre && be_genero && be_documento && be_fecha_nacimiento)"
                        class="btn btn-primary" (click)="agregarBeneficiarioPrincipal('hijo')">Agregar
                        beneficiario</button>
                    </span>

                    <span *ngIf="be_menor_edad =='no'">
                      <button [disabled]="!(be_nombre && be_genero && be_documento && be_fecha_nacimiento)"
                        class="btn btn-primary" (click)="agregarBeneficiarioPrincipal('hijo')">Agregar
                        beneficiario</button>
                    </span>
                  </span>
                  <span *ngIf="be_edad > 41"><b>hasta los cuarenta (40) años más 364 dias</b></span>
                </div>

                <!-- Parentesco HERMANOS -->
                <div *ngIf="parentesco =='hermanos'">
                  <span *ngIf="be_edad <=41 && be_edad >=0">
                    <button [disabled]="!(be_nombre && be_genero && be_documento && be_fecha_nacimiento)"
                      class="btn btn-primary" (click)="agregarBeneficiarioPrincipal('hijo')">Agregar
                      beneficiario</button>
                  </span>
                  <span *ngIf="be_edad > 41"><b>hasta los cuarenta (40) años más 364 dias</b></span>
                </div>

                <!-- Parentesco PADRES -->
                <div *ngIf="parentesco =='padres'">
                  <div class="col-12 text-center mt-3">
                    <div class="input-group input-group-lg m-auto mb-3" style="width: 300px;">
                      <input type="number" [(ngModel)]="be_documento" class="form-control" placeholder="Documento">
                      <button class="btn btn-outline-secondary" type="button" *ngIf="be_documento != documento"
                        (click)="setDocumentoBenficiario(be_documento)">
                        <i *ngIf="!loaded" class="fa fa-check"></i> <!-- Ícono de validación -->
                        <div *ngIf="loaded" class="spinner-grow" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </button>
                      <span class="mt-3" *ngIf="be_documento == documento" style="color: red;">No puedes asegurar al
                        tomador principal del servicio como beneficiario</span>
                    </div>
                  </div>

                  <div class="col-12 text-center mt-3">
                    <h5>{{be_nombre}}</h5>
                  </div>

                  <div class="col-12 text-center mt-1">
                    <h6>{{be_genero}}</h6>
                  </div>

                  <h2 class="mt-4" *ngIf="be_edad >=1 && be_edad <=100">{{be_edad}} Años</h2>


                  <span *ngIf="be_edad <=81 && be_edad >=0">
                    <button [disabled]="!(be_nombre && be_genero && be_documento && be_fecha_nacimiento)"
                      class="btn btn-primary" (click)="agregarBeneficiarioPrincipal('hijo')">Agregar
                      beneficiario</button>
                  </span>
                  <span *ngIf="be_edad > 81"><b>hasta los cuarenta (80) años más 364 dias</b></span>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- Modal agregar beneficiarios Adicional -->
    <div class="modal fade" id="addBeneficiariosAdicional" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-center">Agregar Beneficiarios Adicional</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">

              <!-- <div class="col-12 text-center mt-3">
                <h3>${{valorBeneficiarioAdicional | decimal}}</h3>
                <h3>${{plan_mascota_adicional | decimal}}</h3>
                <h3>${{plan_personal_adicional | decimal}}</h3>
              </div>               -->
              <div class="col-12 text-center mt-3">

                <input [(ngModel)]="be_parentezco" class="btn-check" type="radio" name="inlineRadioOptions"
                  id="inlineRadio5" value="abuelo" (click)="valorBeneficiarioAdicional =''" autocomplete="off">
                <label class="btn btn-outline-primary" for="inlineRadio5">Abuelo</label>

                <input [(ngModel)]="be_parentezco" class="btn-check" type="radio" name="inlineRadioOptions"
                  id="inlineRadio6" value="tio" (click)="valorBeneficiarioAdicional =''" autocomplete="off">
                <label class="btn btn-outline-primary" for="inlineRadio6">Tio</label>

                <input [(ngModel)]="be_parentezco" class="btn-check" type="radio" name="inlineRadioOptions"
                  id="inlineRadio7" value="sobrino" (click)="valorBeneficiarioAdicional =''" autocomplete="off">
                <label class="btn btn-outline-primary" for="inlineRadio7">Sobrino</label>

                <input [(ngModel)]="be_parentezco" class="btn-check" type="radio" name="inlineRadioOptions"
                  id="inlineRadio8" value="conyuge" (click)="valorBeneficiarioAdicional =''" autocomplete="off">
                <label class="btn btn-outline-primary" for="inlineRadio8">Cónyuge</label>

                <input [(ngModel)]="be_parentezco" class="btn-check" type="radio" name="inlineRadioOptions"
                  id="inlineRadio9" value="cuñado" (click)="valorBeneficiarioAdicional =''" autocomplete="off">
                <label class="btn btn-outline-primary" for="inlineRadio9">Cuñado</label>

                <input [(ngModel)]="be_parentezco" class="btn-check" type="radio" name="inlineRadioOptions"
                  id="inlineRadio10" value="primo" (click)="valorBeneficiarioAdicional =''" autocomplete="off">
                <label class="btn btn-outline-primary" for="inlineRadio10">Primo</label>

                <input [(ngModel)]="be_parentezco" class="btn-check" type="radio" name="inlineRadioOptions"
                  id="inlineRadio11" value="hermano" (click)="valorBeneficiarioAdicional =''" autocomplete="off">
                <label class="btn btn-outline-primary" for="inlineRadio11">Hermano</label>

                <input [(ngModel)]="be_parentezco" class="btn-check" type="radio" name="inlineRadioOptions"
                  id="inlineRadio12" value="nieto" (click)="valorBeneficiarioAdicional =''" autocomplete="off">
                <label class="btn btn-outline-primary" for="inlineRadio12">Nieto</label>

                <input [(ngModel)]="be_parentezco" class="btn-check" type="radio" name="inlineRadioOptions"
                  id="inlineRadio13" value="suegro" (click)="valorBeneficiarioAdicional =''" autocomplete="off">
                <label class="btn btn-outline-primary" for="inlineRadio13">Suegro</label>

              </div>


              <div class="col-12 text-center mt-3">
                <h3>{{be_parentezco | titlecase}}</h3>
                <span style="margin-top: 4%;" *ngIf="be_parentezco">hasta los setenta (70) años más 364
                  dias</span>
              </div>


              <div *ngIf="be_parentezco" class="col-12 text-center mt-3">
                <h6>El beneficiario es menor de edad?</h6>
                <div class="form-check form-check-inline">
                  <input [(ngModel)]="be_menor_edad" class="form-check-input" type="radio" (click)="clearFields()"
                    name="inlineRadioOptions111" id="inlineRadio33" value="si">
                  <label class="form-check-label" for="inlineRadio33">SI</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [(ngModel)]="be_menor_edad" class="form-check-input" type="radio" (click)="clearFields()"
                    name="inlineRadioOptions111" id="inlineRadio44" value="no">
                  <label class="form-check-label" for="inlineRadio44">NO</label>
                </div>
              </div>



              <div class="col-12 text-center mt-3" *ngIf="be_menor_edad == 'no' || be_menor_edad == 'si'">
                <div class="input-group input-group-lg m-auto" style="width: 300px;">
                  <input type="number" [(ngModel)]="be_documento" class="form-control" placeholder="Documento">
                  <button class="btn btn-outline-secondary" type="button"
                    *ngIf="be_menor_edad == 'no' && be_documento != documento"
                    (click)="setDocumentoBenficiario(be_documento)">
                    <i *ngIf="!loaded" class="fa fa-check"></i> <!-- Ícono de validación -->
                    <div *ngIf="loaded" class="spinner-grow" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                  <span class="mt-3" *ngIf="be_documento == documento" style="color: red;">No puedes asegurar al tomador
                    principal del servicio como beneficiario</span>
                </div>
              </div>

              <div class="col-12 text-center mt-3" *ngIf="be_menor_edad=='si'">
                <div class="input-group input-group-lg m-auto" style="width: 300px;">
                  <input type="text" [(ngModel)]="be_nombre" class="form-control" placeholder="Nombre">
                </div>
              </div>
              <div class="col-12 text-center mt-3" *ngIf="be_menor_edad=='no'">
                <h5>{{be_nombre}}</h5>
              </div>

              <div class="col-12 text-center mt-3" *ngIf="be_menor_edad=='si'">
                <div class="form-check form-check-inline">
                  <input [(ngModel)]="be_genero" class="form-check-input" type="radio" name="inlineRadioOptions222"
                    id="inlineRadio144" value="masculino">
                  <label class="form-check-label" for="inlineRadio144">Masculino</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [(ngModel)]="be_genero" class="form-check-input" type="radio" name="inlineRadioOptions333"
                    id="inlineRadio244" value="femenino">
                  <label class="form-check-label" for="inlineRadio244">Femenino</label>
                </div>
              </div>

              <div class="col-12 text-center mt-1" *ngIf="be_menor_edad=='no'">
                <h6>{{be_genero}}</h6>
              </div>

              <div class="col-12 text-center mt-1">
                <h4  *ngIf="be_edad >=1 && be_edad <=100">{{be_edad}} Años</h4>
              </div>              

              <div class="col-12 text-center mt-3" *ngIf="be_menor_edad=='si'">
                <div class="input-group input-group-lg m-auto" style="width: 300px;">
                  <input type="date" [(ngModel)]="be_fecha_nacimiento" class="form-control"
                    placeholder="Fecha nacimiento" (change)="calcularEdadBeneficiarios()">
                </div>

                <h4 class="mt-4" *ngIf="be_edad >=1 && be_edad <=100">{{be_edad}} Años</h4>
                <span *ngIf="be_edad >=18" style="color: red;">Debe de ser menor de edad</span>
              </div>


              <div>{{msg}}</div>

              <div class="col-12 text-center">
                <h5 style="color: red !important;">{{msgBe}}</h5>
              </div>


              <div class="col-12 text-center mt-2 mb-3">
                <div *ngIf="be_edad > 41"><b>hasta los cuarenta (70) años más 364 dias.</b></div>
                <span *ngIf="be_edad <=70 && be_edad >=0">
                  <button [disabled]="!(be_nombre && be_genero && be_documento && be_fecha_nacimiento)"
                    class="btn btn-primary mt-3" (click)="agregarBeneficiarioAdicional()">Agregar beneficiario
                    Adicional</button>
                </span>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- Modal agregar beneficiarios Adicional Mascotas -->
    <div class="modal fade" id="addBeneficiariosAdicionalMascotas" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-center">Agregar Beneficiarios Adicional Mascota</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 text-center mt-3">
                <h3>${{valorBeneficiarioAdicional | decimal}}</h3>
              </div>

              <div class="col-12 text-center mt-3">
                <h3>{{be_parentezco | titlecase}}</h3>
              </div>

              <div class="col-12 text-center mt-3">
                <div class="input-group input-group-lg m-auto" style="width: 300px;">
                  <input type="text" [(ngModel)]="be_nombre" class="form-control" placeholder="Nombre">
                </div>
              </div>

              <span *ngIf="be_parentezco =='mascota' ">
                <div class="col-12 text-center mt-3">

                  <div class="form-check form-check-inline">
                    <input [(ngModel)]="be_tipo_mascota" class="form-check-input" type="radio"
                      name="inlineRadioOptions4" id="inlineRadio3" value="gato">
                    <label class="form-check-label" for="inlineRadio4">Gato</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input [(ngModel)]="be_tipo_mascota" class="form-check-input" type="radio"
                      name="inlineRadioOptions5" id="inlineRadio4" value="perro">
                    <label class="form-check-label" for="inlineRadio5">Perro</label>
                  </div>
                </div>

                <div class="col-12 text-center mt-3">
                  <div class="input-group input-group-lg m-auto" style="width: 300px;">
                    <input type="text" [(ngModel)]="be_raza" class="form-control" placeholder="Raza">
                  </div>
                </div>
                <div class="col-12 text-center mt-3">
                  <button [disabled]="!(be_nombre && be_raza && be_tipo_mascota)" class="btn btn-primary"
                    (click)="agregarBeneficiarioAdicionalMascotas()">Agregar beneficiario Adicional</button>
                </div>
              </span>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-md-4 col-xs-12 text-center mt-0">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h2><b>Asegurado Principal</b></h2>
              <hr>
            </div>
            <div class="col-12">
              <h3>{{nombre | titlecase}}</h3>
            </div>
            <div class="col-12" *ngIf="documento">
              <h2>{{documento}} </h2>
            </div>
            <div class="col-12" *ngIf="edad">
              <h2>{{edad}} Años</h2>
            </div>
            <div class="col-12">
              <h2>{{ciudad | titlecase}}</h2>
            </div>
            <div class="col-12">
              <h2>{{estado_civil | titlecase}}</h2>
            </div>

            <!-- <div class="col-12 text-center mt-3">
              <div class="input-group input-group-lg m-auto" style="width: 300px;">
                <input type="number" [(ngModel)]="documento" class="form-control" placeholder="Documento cc">
              </div>
            </div> -->

            <div class="col-12 text-center mt-3">
              <div class="input-group input-group-lg m-auto" style="width: 300px;">
                <input type="number" [(ngModel)]="contacto" class="form-control" placeholder="Teléfono">
              </div>
              <div *ngIf="!contacto" class="text-danger mt-2">
                * El campo Teléfono es requerido.
              </div>
            </div>
            <div class="col-12 text-center mt-3">
              <div class="input-group input-group-lg m-auto" style="width: 300px;">
                <input type="text" [(ngModel)]="email" class="form-control" placeholder="Email">
              </div>
              <div *ngIf="!email" class="text-danger mt-2">
                * El campo Email es requerido.
              </div>
            </div>


            <div class="col-12">
              <hr>
              <h5>{{producto.nombre | titlecase}}</h5>
              <h5>{{plan_nombre}}</h5>
              <h5>${{plan_precio | decimal}}</h5>
              <h6>Beneficiarios ({{be_totalItems}}) Incluidos</h6>
              <h6>Beneficiarios Adicionales ({{be_beneficios_adicional_list.length}})</h6>
              <h6>Beneficiarios Adicionales Mascotas ({{be_beneficios_adicional_mascotas_list.length}})</h6>
              <h4>Total ${{valorTotal | decimal}}</h4>
            </div>

            <div class="col-12 text-center mt-3">
              <button [disabled]="!(email && contacto && documento)" type="button" (click)="enviarSolicitud()"
                class="btn btn-lg btn-success">Realizar solicitud, una vez adicione o registre todos los beneficiarios
                del plan.</button>
            </div>

          </div>

        </div>
      </div>

    </div>

    <div class="col-md-8 col-xs-12">
      <div class="card">
        <!-- BENEFICIARIOS PRINCIPALES -->
        <div class="card-body">
          <h5>Beneficiarios</h5>
          <div class="card" *ngIf="producto.id ==1">
            <div class="card-body">
              <h5>
                <button [disabled]="be_beneficios_conyugue_list.length >=1" class="btn btn-sm btn-outline-secondary"
                  (click)="addBeneficiario('conyugue')"><i class="fa fa-plus"></i></button>
                Conyugue <small>(Max 1)</small>
              </h5>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Nombre</th>
                      <th scope="col">Genero</th>
                      <th scope="col">Fecha Nacimiento</th>
                      <th scope="col">Edad</th>
                      <th scope="col">Documento</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of be_beneficios_conyugue_list; let i = index">
                      <td>{{item.nombre | titlecase}}</td>
                      <td>{{item.genero | titlecase}}</td>
                      <td>{{item.fecha_nacimiento}}</td>
                      <td><span *ngIf="item.edad">{{item.edad}} Años</span></td>
                      <td>{{item.documento}}</td>
                      <td><button class="btn btn-sm btn-outline-secondary" (click)="deleteBeneficiarioConyugue(i)"><i
                            class="fa fa-ban"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h5><button class="btn btn-sm btn-outline-secondary" (click)="addBeneficiario('hijos')"><i
                    class="fa fa-plus"></i></button> Hijos</h5>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Nombre</th>
                      <th scope="col">Genero</th>
                      <th scope="col">Fecha Nacimiento</th>
                      <th scope="col">Edad</th>
                      <th scope="col">Documento</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of be_beneficios_hijos_list; let i = index">
                      <td>{{item.nombre | titlecase}}</td>
                      <td>{{item.genero | titlecase}}</td>
                      <td>{{item.fecha_nacimiento}}</td>
                      <td><span *ngIf="item.edad">{{item.edad}} Años</span></td>
                      <td>{{item.documento}}</td>
                      <td><button class="btn btn-sm btn-outline-secondary" (click)="deleteBeneficiarioHijo(i)"><i
                            class="fa fa-ban"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card" *ngIf="producto.id ==2">
            <div class="card-body">
              <h5><button class="btn btn-sm btn-outline-secondary" (click)="addBeneficiario('hermanos')"><i
                    class="fa fa-plus"></i></button> Hermanos</h5>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Nombre</th>
                      <th scope="col">Genero</th>
                      <th scope="col">Fecha Nacimiento</th>
                      <th scope="col">Edad</th>
                      <th scope="col">Documento</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of be_beneficios_hermanos_list; let i = index">
                      <td>{{item.nombre | titlecase}}</td>
                      <td>{{item.genero | titlecase}}</td>
                      <td>{{item.fecha_nacimiento}}</td>
                      <td><span *ngIf="item.edad">{{item.edad}} Años</span></td>
                      <td>{{item.documento}}</td>
                      <td><button class="btn btn-sm btn-outline-secondary" (click)="deleteBeneficiarioHermano(i)"><i
                            class="fa fa-ban"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h5><button [disabled]="be_beneficios_padres_list.length >=2" class="btn btn-sm btn-outline-secondary"
                  (click)="addBeneficiario('padres')"><i class="fa fa-plus"></i></button> Padres o Suegros <small>(Max
                  2)</small></h5>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Nombre</th>
                      <th scope="col">Genero</th>
                      <th scope="col">Fecha Nacimiento</th>
                      <th scope="col">Edad</th>
                      <th scope="col">Documento</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of be_beneficios_padres_list; let i = index">
                      <td>{{item.nombre | titlecase}}</td>
                      <td>{{item.genero | titlecase}}</td>
                      <td>{{item.fecha_nacimiento}}</td>
                      <td><span *ngIf="item.edad">{{item.edad}} Años</span></td>
                      <td>{{item.documento}}</td>
                      <td><button class="btn btn-sm btn-outline-secondary" (click)="deleteBeneficiarioPadre(i)"><i
                            class="fa fa-ban"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>

        <!-- BENEFICIARIOS ADICIONALES -->
        <div class="card-body">
          <h5>Beneficiarios Adicionales</h5>
          <div class="card">
            <div class="card-body">
              <h5><button class="btn btn-sm btn-outline-secondary" (click)="addBeneficiarioAdicional()"><i
                    class="fa fa-plus"></i></button> Agregar beneficiario</h5>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Parentesco</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Genero</th>
                      <th scope="col">Fecha Nacimiento</th>
                      <th scope="col">Edad</th>
                      <th scope="col">Documento</th>
                      <th scope="col">valor</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of be_beneficios_adicional_list; let i = index">
                      <td>{{item.parentesco | titlecase}} {{item.tipo_mascota | titlecase}}</td>
                      <td>{{item.nombre | titlecase}}</td>
                      <td>{{item.genero | titlecase}}</td>
                      <td>{{item.fecha_nacimiento}}</td>
                      <td><span *ngIf="item.edad">{{item.edad}} Años</span></td>
                      <td>{{item.documento}}</td>
                      <td>${{item.valor | decimal}}</td>
                      <td><button class="btn btn-sm btn-outline-secondary" (click)="deleteBeneficiarioAdicional(i)"><i
                            class="fa fa-ban"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- AGREGAR MASCOTAS -->
          <div class="card">
            <div class="card-body">
              <h5><button class="btn btn-sm btn-outline-secondary" (click)="addBeneficiarioAdicionalMascotas()"><i
                    class="fa fa-plus"></i></button> Agregar beneficiario Mascota</h5>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Parentesco</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Raza</th>
                      <th scope="col">valor</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of be_beneficios_adicional_mascotas_list; let i = index">
                      <td>{{item.parentesco | titlecase}}</td>
                      <td>{{item.nombre | titlecase}}</td>
                      <td>{{item.tipo_mascota | titlecase}}</td>
                      <td>${{item.valor | decimal}}</td>
                      <td><button class="btn btn-sm btn-outline-secondary"
                          (click)="deleteBeneficiarioAdicionalMascotas(i)"><i class="fa fa-ban"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>



  </div>

  <!-- PASO 9 -->
  <div class="mt-5" style="max-width: 400px; margin-left: auto;margin-right: auto;" *ngIf="paso ==9">
    <div class="row">
      <div class="col-12">
        <h2>Solicitud #{{solicitud.id}}</h2>
      </div>
      <div class="col-12">
        <h4>Nombre {{solicitud.nombre}}</h4>
      </div>
      <div class="col-12">
        <h4>Documento {{solicitud.documento}}</h4>
      </div>
      <div class="col-12">
        <h4>Contacto {{solicitud.contacto}}</h4>
      </div>
      <div class="col-12">
        <h4>Email {{solicitud.email}}</h4>
      </div>
      <div class="col-12">
        <h4>Producto {{solicitud.producto_nombre}}</h4>
      </div>
      <div class="col-12">
        <h2>Valor ${{solicitud.valor | decimal}}</h2>
      </div>

      <form ngNoForm action="https://checkout.wompi.co/p/" method="GET">
        <!-- OBLIGATORIOS -->
        <input type="hidden" name="public-key" value="pub_test_E2Rk8Va2196nib5NiytsFWEARcEJXM53" />
        <!-- <input type="hidden" name="public-key" value="pub_test_RnwTqKbijfLGS7aq48rhdRPplSByVTUo" /> -->
        <input type="hidden" name="currency" value="COP" />
        <input type="hidden" name="amount-in-cents" value="{{valorTotal * 100}}" />
        <input type="hidden" name="reference" value="30-{{solicitud.id}}-{{solicitud.documento}}-8130069602" />
        <!-- OPCIONALES -->
        <input type="hidden" name="redirect-url"
          value="https://aplicacion.lobyseguros.com/pay/wompi/{{solicitud.id}}" />
        <div class="text-center mt-4">
          <button type="submit" [disabled]="!email || !contacto" class="btn btn-lg btn-primary">Pagar con Wompi</button>
        </div>
        <div class="text-center">
          <img class="img-fluid  mt-4" src="assets/medios-de-pagos.png" width="420px">
        </div>
      </form>

      <!-- <button type="button" (click)="procesarPago()"
      class="btn btn-lg btn-success">Realizar pago</button> -->
    </div>
  </div>

  <div><img src="assets/footer-home.jpg" class="img-fluid footer-image"></div>
  <!-- <div class="text-center d-none d-sm-block"><b>Versión</b> 2.46</div> -->
</section>